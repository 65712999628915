.ppmap {
    width: 100%;
    margin-top: 40px;
    border-collapse: collapse;
}

.ppmap-head {
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid var(--color-border);
    font-weight: 800;
    text-align: left;
    margin-top: 40px;
}

.ppmap-row {
    width: 100%;
    text-align: left;
}

.ppmap-row-ok {
    background-color: var(--color-primary);
}

.ppmap-row-ok .ppmap-acct {
    color: black !important;
}

.ppmap-acct, .ppmap-sel-plan, .ppmap-sel-acct {
    float: left;
    text-align: left;
    padding: 5px;
    height: 40px;
    line-height: 40px;
}

.ppmap-acct {
    width: 320px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.ppmap-sel-plan {
    width: 260px;
}

.ppmap-sel-acct {
    width: 190px;
}

.ppmap-sel-investment-provider{
    width: 260px;
}

.ppmap-select-container {
    width: 100%;
    height: 36px !important;
    margin-right: 5px;
    position: relative;
}

.ppmap-ignore {
    color: white;
    background-color: var(--color-accent-dark);
    padding: 3px 10px;
    border-radius: 5px;
}

.ppmap-create {
    color: white;
    background-color: var(--color-primary);
    padding: 3px 10px;
    border-radius: 5px;
}

.ppmap-row-ok * .select__control * {
    color: white !important;
}

.ppmap-row {
    width: 100%;
    text-align: left;
}

.ppmap-import-button {
    margin-top: -2px !important;
    float: right !important;
}

.PageControls{
    margin-top: 10px;
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}
.previous{
    width: 15px;
    height: 15px;
    background-image: url('/images/icons/next-arrow.png');
    background-size: contain;
    background-repeat: no-repeat;
    rotate: 180deg;
    margin-right: 10px;
}
.next{
    width: 15px;
    height: 15px;
    background-image: url('/images/icons/next-arrow.png');
    background-size: contain;
    background-repeat: no-repeat;
    margin-left: 10px;
}

.SelectOption{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}


.SelectOption:hover{
    background-color: rgba(100,100,100,0.4);
}
/* next-arrow.png */