/** Plan Editor Styling **/

.plan-editor-content{
    display: grid;
}

/* Plan Card Styling */
.plan-editor-list-cards {
    margin-bottom: -30px;
    min-height: calc(100vh - 365px);
    border-right: 1px solid var(--color-border);
}

.plan-editor-card-selected {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    border-bottom: 0.5px solid rgba(200, 200, 200, 0.5);
    height: 70px;
    width: 300px;
    box-shadow: -3px 0 0 0 var(--color-primary);
}

.plan-editor-card-open {
    box-shadow: 3px 0 0 0 var(--color-background);
}

.plan-editor-card {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    border-bottom: 1px solid var(--color-border);
    width: 300px;
    height: 70px;
}

.plan-editor-card:hover {
    background-color: rgba(200, 200, 200, 0.1);
    cursor: pointer;
    /* box-shadow: -3px 0 0 0 var(--color-primary); */
}

.plan-editor-card-lacking {
    background: linear-gradient(90deg, rgba(217, 217, 217, 0) 0%, var(--color-accent-45) 48.47%, var(--color-accent) 100%);
}

/** Plan Page Styling **/

.plan-editor-page {
    /* display: flex; */
    padding-left: 40px;
    width: 100%;
}

.plan-editor-page h2{
    /* padding-left: 40px; */
    padding-top: 0px;
    padding-bottom: 0px;
}

.plan-editor-information {
    display: grid;
    padding-left: 5px;
    height: 200px;
    width: 100%;
    grid-template-columns: [col-1-start] 18% [col-2-start] 4% [break1-end] 14% [col-3-start] 4% [break2-end] 60% [col-3-end];
    grid-template-rows: [row-1-start] 50% [row-2-start] 50% [row-2-end];
}

.plan-editor-information h3 {
    white-space: nowrap;
}

.plan-editor-information-id{
    grid-row-start: row-1-start;
    grid-row-end: row-2-start;
    grid-column-start: col-1-start;
    grid-column-end: col-3-start;
}

.plan-editor-information-name{
    grid-row-start: row-1-start;
    grid-row-end: row-2-start;
    grid-column-start: break2-end;
    grid-column-end: col-4-end;
}

.plan-editor-information-pye{
    grid-row-start: row-2-start;
    grid-row-end: row-2-end;
    grid-column-start: col-1-start;
    grid-column-end: col-2-start;
    min-width: 120px;
}

.plan-editor-information-terminated{
    grid-row-start: row-2-start;
    grid-row-end: row-2-end;
    grid-column-start: break1-end;
    grid-column-end: col-3-start;
    padding-top: 25px;
    padding-left: 15px;
    align-self: center;
    justify-self: center;
    display: flex;
    align-items: center;
    /* min-width: 120px; */
}

@media only screen and (max-width: 1190px) {
    .plan-editor-information-terminated {
        display: none;
    }}

.plan-editor-information-directory{
    grid-row-start: row-2-start;
    grid-row-end: row-2-end;
    grid-column-start: break2-end;
    grid-column-end: col-3-end;
}

/* Plan Page -> Header styling */

.plan-page-header{
    display: flex;
    align-items: center;
    gap: 30px;
    margin-top: -10px;
    white-space: nowrap;
}

.plan-page-header h2 {
    line-height: 60px;
}

.plan-page-header p {
    color: var(--color-text-light)
}

/* Plan Page -> Accounts styling */

.account-add{
    display: flex;
    flex-direction: row;
    margin-bottom: 12px;
    margin-top: 20px;
}

.account-add-icon {
    float: left;
    margin-left: 16px;
    height: 38px;
    line-height: 40px;
    padding-left: 45px;
    background-image: var(--create-icon);
    background-position: left;
    background-repeat: no-repeat;
    background-size: 40px;
    font-size: 4px;
    color: transparent;
    width: 0;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.15s ease-in-out;
}

.account-add-icon:hover {
    width: 200px;
    color: var(--color-primary-dark);
    font-size: 16px;
}

.plan-editor-account-list {
    padding-left: 5px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    /* grid-template-areas: 'h h h .'; */
    align-items: center;
    clear: both;
}

/* Button Styling */
.plan-editor-button {
    padding-left: 22px;
    /* padding-top: 15px; */
    color: var(--color-primary-dark);
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    background-image: url('/images/icons/confirm.png');
    background-position: left;
    background-repeat: no-repeat;
    background-size: 16px;
}

/* Page Navigator */
.plan-editor-page-navigator-bold{
    font-weight: bold;
}

.plan-editor-page-navigator-format{
    display: flex;
    position: relative;
    /* padding-right: 60px; */
    margin-left: auto;
    gap: 15px
}

/* */

.plan-editor-plan-date-container {
    /* width: 380px; */
    width: calc(100% - 232px);
    /* border: 1px solid var(--color-border); */
    position: relative;
    float: left;
    margin-top: 10px;
    margin-left: 0px;
    height: 40px;
    line-height: 34px;
    border-radius: 4px;
    /* padding: 0 8px; */
    cursor: pointer;
    transition: all 0.15s ease-in-out;
}

.plan-editor-instructions {
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
    padding-top: 30px;
}

.plan-editor-instructions-list {
    padding: 10px;
    list-style-position: inside;
}

.plan-editor-header{
    display: flex;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 10px;
}

.plan-editor-table{
    display: flex;
}

.arrow-image{
    height: 20px;
    width: 20px;
    align-self: center;
    cursor: pointer;
}

.plan-editor-header h2 {
    display: flex;
    flex-direction: row;
    padding-top: 10px;
    padding-bottom: 0px;
}

.plan-editor-card-content {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-left: 10px;
    padding-top: 0 !important;
    margin-top: 0 !important;
}

.plan-editor-info-header{
    display: flex;
    gap: 5px;

}

.plan-editor-account-list-header{
    display: grid;
    grid-template-columns: [col-1-start] 20% [col-2-start] 20% [col-3-start] 30% [col-4-start] 15% [col-5-start] 15% [col-end];
    border-bottom: 1px solid var(--color-border) !important;

}

.plan-editor-account-list-content{
    display: grid;
    grid-template-columns: [col-1-start] 20% [col-2-start] 20% [col-3-start] 30% [col-4-start] 15% [col-5-start] 15% [col-end];
    border-top: 1px solid var(--color-border);
    align-items: center;
}

.settings-account-edit{
    display: flex;
}

.settings-account-edit-text{
    padding-left: 10px;
    color: var(--color-primary);
}

.settings-active-dot{
    height: 12px;
    width: 12px;
    margin-left: 10px;
    margin-top: 11px;
    margin-right: -5px;
    background-color: #ff0000;
    border-radius: 50%;
    display: inline-block;
}

.settings-active-format{
    display: flex;
}

.tpa-plan-editor-calendar{
    display: flex;
}

.tpa-plan-editor-calendar config-date-container{
    margin-right: 100px;
}

.select-plan-calendar-format{
    display: flex;
}

.plan-editor-sync-button {
    margin-top: 44px;
    margin-left: 20px;
    color: white;
    text-align: center;
    width: 80px;
    height: 30px;
    background-color: var(--color-accent);
    border-radius: 20px;
}

.provider-select-container{
    margin-left: 0;
    width: calc(100% - 10px);
    margin-top: 2px;
    position: relative;
}


.provider-select__control {
    height: 36px !important;
    min-height: 36px !important;
    max-height: 36px;
    border: none !important;
    border-radius: 20px !important;
    padding: 0 5px ;
    background-color: var(--color-background-input) !important;
    display: flex;
    align-items: center;
}

.provider-select__control input {
    height: 22px !important;
    font-size: 16px !important;
}

.provider-select__input {
    height: 22px !important;
    font-size: 16px !important;
    color: var(--color-text-light) !important;
}

.provider-select__value-container {
    width: 100%;
}

.provider-select__control:hover {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
}

.provider-select__control--is-focused {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
}

.provider-select__placeholder {
    color: var(--color-scrollbar) !important;
}

.provider-select__indicator {
    opacity: 0.65;
    padding: 0 !important;
    width: 14px !important;
    height: 14px !important;
    margin-bottom: 6px !important;
}

.provider-select__indicator-separator {
    background-color: transparent !important;
    display: none !important;
}

.provider-select__single-value {
    height: 16px !important;
    line-height: 16px !important;
    font-size: 16px !important;
    color: var(--color-text) !important;
    margin-left: 0 !important;
    margin-right: 4px !important;
}

.provider-select__single-value > div {
    height: 16px !important;
    line-height: 16px !important;
}

.provider-select__value-container, .provider-select__control input {
    box-shadow: none !important;
    border: none !important;
    margin: 0 !important;
}

.provider-select__menu, .provider-select__menu-list {
    border: none !important;
    font-size: 16px !important;
    text-align: left !important;
    min-width: 176px !important;
    background-color: var(--color-menu) !important;
}

.provider-select__option {
    height: 28px !important;
    line-height: 28px !important;
    padding: 0 10px !important;
    overflow: hidden !important;
}

.provider-select__option:hover, .provider-select__option--is-focused {
    background-color: var(--color-border) !important;
    color: var(--color-text) !important;
}

.provider-select__option--is-selected {
    background-color: var(--color-primary) !important;
    color: var(--color-text) !important;
}

.provider-select__menu-list::-webkit-scrollbar {
    width: 6px;
}

.provider-select__menu-list::-webkit-scrollbar-track {
    background: rgba(120,120,120,0.5);
}

.provider-select__menu-list::-webkit-scrollbar-thumb {
    background: var(--color-scrollbar);
    border-radius: 3px;
    cursor: pointer; 
}

.plan-editor-loading div {
    margin-top: 3px;
    float: left;
}

.plan-editor-loading-text {
    /* display: inline-block; */
    color: var(--color-text-light);
    font-weight: 500;
    padding-left: 14px;
    font-size: 16px;
    line-height: 40px;
    height: 40px;
}

.plan-editor-accounts-list * .stack-list-header-left {
    margin-left: 0 !important;
    padding-left: 23px !important;
}

.plan-editor-accounts-list .stack-list-header {
    margin: 0 !important;
}

/* Account card */
.plan-editor-account-card {
    display: block;
    height: 40px;
    line-height: 40px;
    padding: 5px 10px;
    min-width: 900px;
    border-bottom: 1px solid var(--color-border-light);
} 

.plan-editor-account-provider {
    float: left;
    height: 40px;
    line-height: 40px;
    width: 220px;
}

.plan-editor-account-left {
    float: left;
    height: 40px;
    line-height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.plan-editor-account-meta {
    float: right;
    height: 40px;
    line-height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.plan-editor-account-meta-saving{
    text-align-last: center;
}

.plan-editor-account-left .stack-list-meta-col {
    color: var(--color-text) !important;
}

.plan-editor-account-action {
    float: left;
    height: 40px;
    line-height: 40px;
    background-position: left;
    padding-left: 24px;
    width: 60px;
}

.plan-editor-account-value {
    padding-left: 13px;
    padding-top: 8px;
    display: inline-block;
    max-width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.plan-editor-account-value-inactive {
    padding-left: 13px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: var(--color-text-light);
}

.plan-editor-account-value-link {
    padding-left: 35px;
    padding-top: 6px;
    background-image: url('/images/icons/action-open.png');
    background-position: 12px 12px;
    background-size: 16px;
    background-repeat: no-repeat;
    cursor: pointer;
    transition: all 0.15s ease-in-out;
}

.plan-editor-account-value-link:hover {
    color: var(--color-accent);
}

.plan-editor-account-edit {
    width: calc(100% - 40px) !important;
    margin: 0 !important;
    padding: 10px 13px !important;
    height: 16px !important;
}

.order-by-dropdown{
    width: 200px;
    
}

.QueryMatch {
    box-shadow: 0 0 10px 5px var(--color-accent);
    animation: pulse-75 2s infinite !important;
    transition: box-shadow 0.5s ease-in-out, color 0.15s ease-in-out;
}

.QueryMatch-Text {
    color: var(--color-accent);
}


@keyframes pulse-75 {
    0% {
        box-shadow: 0 0 5px 0 var(--color-accent);
    }
    30% {
        box-shadow: 0 0 15px 5px var(--color-accent);
    }
    100% {
        box-shadow: 0 0 5px 0 var(--color-accent);
    }
}

.load-more-accounts {
    text-align: center;
    border-radius: 5px;
    margin-top: 40px;
    color: var(--color-primary-dark);
    cursor: pointer;
}