.table-editor-column-header{
    position: absolute; 
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    background-color: #0091BD;
    display: flex;

}

.table-editor-row-header{
    position: absolute; 
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    background-color: #0091BD;
    padding: 0;
    margin: 0;
}

.table-editor-header{
    position: absolute;
    font-size: 12px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: white;
    background-color: #0091BD;
}

.column-header:hover{
    cursor: pointer;
}

.table-editor-header-input{
    position: absolute;
    margin: 0 !important;
    padding: 0 !important;
    color: white;
}

.table-editor-cell{
    position: absolute;
    background-color: var(--color-results-focus);
    font-size: clamp(8px, -5vw, 35px);
    padding: 0 !important;
    margin: 0 !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border: 1px solid var(--color-primary-dark);
    word-wrap: normal;
    overflow: auto;
    white-space: pre-line;
} 

.table-editor-cell-hover-row{
    background-color: rgba(0, 145, 189, 0.4);
}

.table-editor-cell-medium-high-confidence{
    background-color: rgba(255, 255, 0, 0.3);
}

.table-editor-cell-medium-confidence{
    background-color: rgba(255, 128, 0, 0.3);
}

.table-editor-cell-low-confidence{
    background-color: rgba(255, 0, 0, 0.3);
}

.table-editor-cell-hover{
    background-color: rgba(240, 170, 133, 1);
    color: black;
}

.table-editor-cell-input{
    position: absolute !important; 
    background-color: var(--color-primary) !important;
    opacity: 0.95;
    padding: 0 !important;
    margin: 0 !important;
    border: none !important;
    border-radius: 1px !important;
    z-index: 3 !important;
    font-size: 0.6rem !important;
    padding-left: 3px !important;
    text-align: center;
    box-shadow: 2px 2px 2px 0px rgba(0,0,0,0.2) !important;
    color: black;
}

.table-editor-new-row-button{
    position: absolute;
    text-overflow: ellipsis;
    background-Image: url('/images/icons/action-create.png');
    background-repeat: no-repeat;
    background-size: 12px;
    background-position: 7px;
    transition: all 0.15s ease-in-out;
    background-color: var(--color-background-input) !important;
    padding-left: 28px;
    padding-right: 8px;
    border-radius: 18px;
    height: 24px;
    line-height: 24px;
    font-size: 12px;
    width: 86px;
    overflow: hidden;
    white-space: nowrap;
    transform: translateX(-50%);
    color: var(--color-primary-dark);
}

.table-editor-new-row-button:hover{
    cursor: pointer;
    text-decoration: underline;
} 

.resize-handle-left{
    width: 3px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    cursor: col-resize;
}   

.resize-handle-right{
    width: 3px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    cursor: col-resize;
}

.resize-handle-top{
    width: 100%;
    height: 3px;
    position: absolute;
    left: 0;
    top: 0;
    cursor: row-resize;
}

.resize-handle-bottom{
    width: 100%;
    height: 3px;
    position: absolute;
    left: 0;
    bottom: 0;
    cursor: row-resize;
}
.hover-tag{
     background-color: var(--color-results);
}